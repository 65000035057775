import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { Grid } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import MenuIcon from "@material-ui/icons/Menu"
import Close from "@material-ui/icons/Close"
import Fade from "@material-ui/core/Fade"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import Headroom from "react-headroom"
import NavItem from "./navItem"

const useStyles = makeStyles((theme) => ({
  headroom: {
    "& .headroom": {
      boxShadow: "rgba(0,0,0,0.1) 0px 0px 15px 0px",
    },
    "& .headroom--unpinned": {
      transform: "translate3d(0px, -500%, 0px) !important",
    },
  },
  root: {
    backgroundColor: "#ffffff",
  },
  container: {
    maxWidth: "1200px",
    margin: "0px auto",
    "& span": {
      textTransform: "none",
    },
  },
  appBar: {
    backgroundColor: "#ffffff",
    boxShadow: "none",
  },
  toolbar: {
    backgroundColor: "white",
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
  },
  menuButtonClose: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
  },
  appBarGrid: {
    padding: "1rem",
  },
  gridItemLogo: {
    display: "flex",
    flexDirection: "row",
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignContent: "center",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  linkMobile: {
    textDecoration: "none",
    padding: "0.5rem",
    color: "#048387",
    fontFamily: "Segoe UI",
  },
  link: {
    textDecoration: "none",
    padding: "0.5rem",
    color: "#048387",
    fontFamily: "Segoe UI",
    position: "relative",
    "&::before": {
      content: "''",
      height: "2px",
      position: "absolute",
      bottom: "-4px",
      transform: "scale(0)",
      background: "rgb(57, 139, 247)",
      transition: "all 0.4s linear 0s",
      left: 0,
      right: 0,
    },
    "&:hover": {
      "&::before": {
        content: "",
        transform: "scale(1)",
      },
    },
  },
  linkActive: {
    textDecoration: "none",
    padding: "0.5rem",
    color: "#048387",
    fontFamily: "Segoe UI",
    position: "relative",
    "&::before": {
      content: "''",
      height: "2px",
      position: "absolute",
      bottom: "-4px",
      transform: "scale(1)",
      background: "rgb(57, 139, 247)",
      transition: "all 0.4s linear 0s",
      left: 0,
      right: 0,
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  navMenu: {
    listStyleType: "none",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    margin: 0,
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenu: {
    listStyleType: "none",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    margin: 0,
    padding: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    height: "300px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  gridItemMobile: {
    textAlign: "center",
    padding: "0.5rem",
  },
  menuIcon: {
    color: "#048387",
    width: "40px",
    height: "40px",
  },
  closeButtonIcon: {
    color: "#048387",
    width: "40px",
    height: "40px",
  },
  fadeContainer: {
    display: "flex",
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 4,
    height: "300px",
  },
  fadePaper: {
    width: "100%",
    borderRadius: 0,
    boxShadow: "none",
  },
}))

export default function AppBarTabs() {
  const classes = useStyles()

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [side]: open })
  }

  const { squidex } = useStaticQuery(graphql`
    query {
      squidex {
        queryHeaderContents {
          data {
            home {
              hr
            }
            aboutUs {
              hr
            }
            services {
              hr
            }
            references {
              hr
            }
            contact {
              hr
            }
            image {
              iv {
                url
              }
            }
          }
        }
      }
    }
  `)

  const header = squidex.queryHeaderContents[0]

  return (
    <Headroom className={classes.headroom}>
      <div className={classes.root}>
        <div className={classes.container}>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar variant="dense" className={classes.toolbar}>
              <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.appBarGrid}
              >
                <Grid item xs={6} className={classes.gridItemLogo}>
                  <a href="/" style={{ cursor: "pointer" }}>
                    <img
                      src={header.data.image.iv[0].url}
                      alt="logo"
                      style={{ marginBottom: 0 }}
                    />
                  </a>
                </Grid>
                <Grid item xs={6} className={classes.linkContainer}>
                  {" "}
                  <ul className={classes.navMenu}>
                    <NavItem section={"home"} title={header.data.home.hr} />
                    <NavItem
                      section={"aboutUs"}
                      title={header.data.aboutUs.hr}
                    />
                    <NavItem
                      section={"services"}
                      title={header.data.services.hr}
                    />
                    <NavItem
                      section={"references"}
                      title={header.data.references.hr}
                    />
                    <NavItem
                      section={"contact"}
                      title={header.data.contact.hr}
                    />
                  </ul>
                </Grid>
              </Grid>
              {state.top === false ? (
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Menu"
                  onClick={toggleDrawer("top", true)}
                >
                  <MenuIcon className={classes.menuIcon} />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="Menu"
                  onClick={toggleDrawer("top", false)}
                >
                  <Close className={classes.closeButtonIcon} />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
        </div>
      </div>
      {state.top && (
        <div className={classes.fadeContainer}>
          <Fade in={state.top}>
            <Paper elevation={4} className={classes.fadePaper}>
              <div item xs={12}>
                {" "}
                <ul
                  className={classes.mobileMenu}
                  onClick={toggleDrawer("top", false)}
                >
                  <NavItem section={"home"} title={header.data.home.hr} />
                  <NavItem section={"aboutUs"} title={header.data.aboutUs.hr} />
                  <NavItem
                    section={"services"}
                    title={header.data.services.hr}
                  />
                  <NavItem
                    section={"references"}
                    title={header.data.references.hr}
                  />
                  <NavItem section={"contact"} title={header.data.contact.hr} />
                </ul>
              </div>
            </Paper>
          </Fade>
        </div>
      )}
    </Headroom>
  )
}
