import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography, Divider, Avatar } from "@material-ui/core"
import ListAltOutlined from "@material-ui/icons/ListAltOutlined"
import ImportContactsOutlined from "@material-ui/icons/ImportContactsOutlined"
import AccountBalanceOutlined from "@material-ui/icons/AccountBalanceOutlined"
import PeopleOutlined from "@material-ui/icons/PeopleOutlined"
import PaymentOutlined from "@material-ui/icons/PaymentOutlined"
import ComputerOutlined from "@material-ui/icons/ComputerOutlined"

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: "1200px",
    margin: "0px auto",
  },
  sectionTitle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "112px",
    position: "relative",
    maxWidth: "1200px",
    margin: "0px auto",
  },
  sectionTitleLabel: {
    fontFamily: "Segoe UI",
    fontSize: "24px",
  },
  arrowDown: {
    width: 0,
    height: 0,
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderTop: "25px solid #034669",
    position: "absolute",
    top: "112px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justify: "center",
    alignItems: "center",
    paddingBottom: "2rem",
    paddingTop: "3rem",
  },
  title: {
    fontSize: "32px",
    fontWeight: 700,
  },
  divider: {
    backgroundColor: "#00806F",
    border: "1px solid #00806F",
    width: "77px",
    height: "2px",
    marginTop: "15px",
  },
  subtitle: {
    marginTop: "15px",
    fontSize: "16px",
  },
  serviceItem: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    height: "400px",
    maxWidth: "300px",
    margin: "2rem",
    padding: "1rem",
    backgroundColor: "#F8F8F8",
  },
  serviceItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
  },
  avatar: {
    height: "75px",
    width: "75px",
    backgroundColor: "#FFFFFF",
    padding: "0.25rem",
    "& svg": {
      color: "#034669",
      height: "47px",
      width: "47px",
    },
  },
  serviceItemTitle: {
    fontWeight: "700",
    textAlign: "center",
    padding: "0.25rem",
    textTransform: "uppercase",
    fontSize: "20px",
  },
  serviceItemServicesContainer: {
    textAlign: "center",
    padding: "0.25rem",
  },
  service: {
    color: "#00816D",
    padding: "0.25rem",
    fontSize: "14px",
  },
}))

export default function Services() {
  const classes = useStyles()

  const { squidex } = useStaticQuery(graphql`
    query {
      squidex {
        queryServicesContents {
          data {
            sectionTitle {
              hr
            }
            title {
              hr
            }
            subtitle {
              hr
            }
            services {
              hr {
                title
                subtitle
                description
                serviceOne
                serviceTwo
                serviceThree
                serviceFour
              }
            }
          }
        }
      }
    }
  `)

  const services = squidex.queryServicesContents[0]

  return (
    <div>
      <div style={{ backgroundColor: "#034669" }}>
        <div className={classes.sectionTitle}>
          <Typography color="secondary" className={classes.sectionTitleLabel}>
            {services.data.sectionTitle.hr}
          </Typography>
          <div className={classes.arrowDown}></div>
        </div>
      </div>
      <div className={classes.container}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography className={classes.title}>
              {services.data.title.hr}
            </Typography>
            <Divider className={classes.divider} />
            <Typography className={classes.subtitle}>
              {services.data.subtitle.hr}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignContent="center"
              alignItems="center"
            >
              {services.data.services.hr.map((service, index) => {
                return (
                  <Grid item className={classes.serviceItem} md={4} xs={12}>
                    <div className={classes.serviceItemContainer}>
                      <Avatar className={classes.avatar}>
                        {index === 0 ? (
                          <ImportContactsOutlined />
                        ) : index === 1 ? (
                          <ListAltOutlined />
                        ) : index === 2 ? (
                          <AccountBalanceOutlined />
                        ) : index === 3 ? (
                          <PeopleOutlined />
                        ) : index === 4 ? (
                          <PaymentOutlined />
                        ) : (
                          <ComputerOutlined />
                        )}
                      </Avatar>
                      <Typography className={classes.serviceItemTitle}>
                        {service.title}
                      </Typography>
                      {/* <Typography
                        style={{
                          color: "#AAAEB2",
                          textAlign: "center",
                          padding: "0.25rem",
                          fontSize: "14px",
                        }}
                      >
                        {service.description}
                      </Typography>
                      <Typography
                        style={{
                          fontStyle: "uppercase",
                          color: "#00816D",
                          padding: "0.25rem",
                          fontSize: "14px",
                        }}
                      >
                        {service.subtitle}
                      </Typography> */}

                      <div className={classes.serviceItemServicesContainer}>
                        <Typography className={classes.service}>
                          {service.serviceOne}
                        </Typography>
                        <Typography className={classes.service}>
                          {service.serviceTwo}
                        </Typography>
                        <Typography className={classes.service}>
                          {service.serviceThree}
                        </Typography>
                        {service.serviceFour !== undefined && (
                          <Typography className={classes.service}>
                            {service.serviceFour}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
