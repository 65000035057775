import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    height: "243px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  rootOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundImage: "linear-gradient(rgba(3, 70, 105, 1),rgba(2, 35, 53, 1))",
    opacity: 0.5,
    height: "100%",
  },
  container: {
    maxWidth: "1200px",
    margin: "0px auto",
    zIndex: 1,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  quoteText: {
    zIndex: 1,
    textTransform: "uppercase",
  },
}))

export default function Quote() {
  const classes = useStyles()

  const { squidex } = useStaticQuery(graphql`
    query {
      squidex {
        queryQuoteContents {
          data {
            title {
              hr
            }
            image {
              iv {
                url
              }
            }
          }
        }
      }
    }
  `)

  const quote = squidex.queryQuoteContents[0]

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${quote.data.image.iv[0].url})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className={classes.rootOverlay} />
      <div className={classes.container}>
        <Typography
          variant="h4"
          color="secondary"
          className={classes.quoteText}
        >
          &bdquo;{quote.data.title.hr}&ldquo;
        </Typography>
      </div>
    </div>
  )
}
