import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import Fab from "@material-ui/core/Fab"
import { Grid, Typography, Divider } from "@material-ui/core"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    height: "958.6px",
    backgroundSize: "cover",
    position: "relative",
  },
  rootOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundImage: "linear-gradient(rgba(3, 70, 105, 1),rgba(2, 35, 53, 1))",
    opacity: 0.5,
    height: "100%",
  },
  mainGrid: {
    height: "100%",
    maxWidth:"1200px",
    margin: "0px auto"
  },
  gridItem: {
    padding: "0.5rem",
    zIndex: 1,
    textAlign: "center"
  },
  divider: {
    backgroundColor: "#00806F",
    border: "1px solid #00806F",
    width: "200px",
  },
  contactUsButton: {
    fontFamily: "Segoe UI",
    fontSize: "16px",
    fontWeight: "none",
    backgroundColor: "#00806F",
    color: "#FFFFFF",
    textTransform: "none",
    "& hover": {
      backgroundColor: "grey",
    },
  },
  title: {
    fontFamily: "Segoe UI",
    textTransform: "uppercase",
    fontSize: "32px",
  },
  subtitle: {
    fontFamily: "Segoe UI",
  },
}))

export default function Jumbotron() {
  const classes = useStyles()

  const { squidex } = useStaticQuery(graphql`
    query {
      squidex {
        queryJumbotronContents {
          data {
            title {
              hr
            }
            description {
              hr
            }
            contactUs {
              hr
            }
            background {
              iv {
                url
              }
            }
          }
        }
      }
    }
  `)

  const jumbotron = squidex.queryJumbotronContents[0]

  return (
    <div
      style={{
        backgroundImage: `url(${jumbotron.data.background.iv[0].url})`,
      }}
      className={classes.root}
    >
      <div className={classes.rootOverlay} />
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.mainGrid}
      >
        <Grid item className={classes.gridItem}>
          <Typography className={classes.title} color="secondary">
            {" "}
            {jumbotron.data.title.hr}
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <Typography className={classes.subtitle} color="secondary">
            {" "}
            {jumbotron.data.description.hr}
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem}>
        <Link to="/#contact" style={{ textDecoration: "none" }}>
          <Fab className={classes.contactUsButton} variant="extended">
              {jumbotron.data.contactUs.hr}
          </Fab>
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}
